import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import * as L from 'leaflet';
import 'leaflet-routing-machine';
import 'lrm-graphhopper';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  options: {};

  stops: any;
  points: any;
  stopsByAreas: any;

  plan: L.Routing.Plan;
  control: L.Routing.Control;
  selectedStop: { id: number, lat: number, lon: number };
  selectedStopIndex = 0;
  selectedRouteId: number;

  map: L.Map;

  schedules: any;

  constructor(private API: Restangular,
              private cd: ChangeDetectorRef) {

  }

  ngOnInit() {

    this.options = {
      layers: [
        L.tileLayer('http://185.180.8.94:8080/tile/{z}/{x}/{y}.png',
          { maxZoom: 18, attribution: '...' })
      ],
      zoom: 8,
      center: L.latLng( -34.09756240, -59.03695014),
      scrollWheelZoom: false,
      doubleClickZoom: true,
      dragging: false,
    };

  }

  selectStop(stopId) {

    this.selectedStop = this.stops.find(s => s.id === stopId );

    if (this.selectedStop && this.map) {
      console.log('flying to ', this.selectedStop);
      this.map.flyTo(new L.LatLng(this.selectedStop.lat, this.selectedStop.lon), 14);
    }
    this.cd.detectChanges();

  }

  onMapReady(map: L.Map) {

    this.map = map;

    this.getRoute(2);

  }

  getRoute(routeId = 1) {

    this.selectedRouteId = routeId;

    this.API.service('public/routes')
      .one(routeId)
      .all('stops')
      .customGET()
      .subscribe(response => {

        this.stops = response.stops;
        this.points = response.points;

        this.stops.map((stop) => {
          const m = /([A-Za-z0-9\sáéíóúÁÉÍÓÚ\"\.]*)(?:\s\((.*)\))?\-\s(.*)/g.exec(stop.name);
          stop.name = m[1];
          stop.desc = m[2];
          stop.area = m[3];
          stop.times = [];

          response.schedules.forEach((times) => {
            stop.times.push(times[stop.id]);
          });

        });

        this.stopsByAreas = this.stops.reduce( (r, { area }) => {
          if (! r.some(o => o.group === area)) {
            r.push({ area, stops: this.stops.filter(v => v.area === area)});
          }
          return r;
        }, []);

        this.stopsByAreas = this.stopsByAreas.filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj.area).indexOf(obj.area) === pos;
        });

        this.initMap();

        this.selectStop(this.stops[0].id);

      });

  }

  getPointsForStop(stop): any[] {
    return this.points.filter((p) => {
      return p.sort_stops == stop.sort;
    });
  }

  initMap() {

    const waypoints = [];

    this.stops.forEach((stop) => {
      const options = {};
      options['id'] = stop.id;
      options['type'] = stop.type;
      const wp = L.Routing.waypoint(
        L.latLng(parseFloat(stop.lat), parseFloat(stop.lon)),
        stop.name,
        options
      );
      waypoints.push(wp);

      this.getPointsForStop(stop).forEach((point) => {
        const options = {};
        options['id'] = 'point_' + point.id;
        options['type'] = 'point';
        const wp = L.Routing.waypoint(
          L.latLng(parseFloat(point.lat), parseFloat(point.lon)),
          "Point #"+point.id,
          options
        );
        waypoints.push(wp);
      })

    });

    const icons = {
      origin: L.divIcon({ className: 'waypoint waypoint-origin', bgPos: [-10, -10] } ),
      destination: L.divIcon({ className: 'waypoint waypoint-destination' }),
      point: L.divIcon({ className: 'waypoint waypoint-point' }),
    };

    this.plan = L.Routing.plan(waypoints, {
      createMarker: (i, wp) => {
        return L
          .marker(wp.latLng, {
            title: wp.name,
            icon: icons[wp.options['type']],
          });
      },
    });

    if (this.control) {
      this.map.removeControl(this.control);
    }

    this.control = L.Routing.control({
      plan: this.plan,
      router: new L.Routing['GraphHopper']('', {
        serviceUrl: 'http://185.180.8.94:8989/route',
        allowUTurns: false
      }),
      lineOptions: {
        styles: [
          { color: 'black', opacity: 0.15, weight: 9},
          { color: 'white', opacity: 0.8, weight: 6},
          { color: '#e00008', opacity: 1, weight: 3}
        ],
        addWaypoints: false,
      },

      fitSelectedRoutes: 'smart',
    }).addTo(this.map);

    this.control.getContainer().style.display = 'None';


  }

  toggleStops() {

  }


}
